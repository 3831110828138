<template>
  <b-form-group>
    <div class="d-flex justify-content-between">
      <label :for="name">{{ label }}</label>
    </div>
    <validation-provider #default="{ errors }" :name="label">
      <b-input-group
        class="input-group-merge"
        :class="errors.length > 0 ? 'is-invalid' : null"
      >
        <b-form-input
          :id="name"
          :value="value"
          @input="$emit('input', $event)"
          :state="errors.length > 0 ? false : null"
          class="form-control-merge"
          :type="passwordFieldType"
          :name="name"
          placeholder="············"
        />
        <b-input-group-append is-text>
          <feather-icon
            class="cursor-pointer"
            :icon="passwordToggleIcon"
            @click="togglePasswordVisibility"
          />
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";

export default {
  name: "PasswordInput",
  components: {
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
  },
  props: {
    value: String,
    name: String,
    label: String,
  },
  data() {
    return { passwordFieldType: "password" };
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>

<style scoped></style>
