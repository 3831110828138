<template>
  <auth-master-layout>
    <b-card-text class="mb-2">
      Please reset your password and start the adventure
    </b-card-text>

    <!-- form -->
    <validation-observer ref="loginValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <password-input
          name="password"
          v-model="payload.password"
          label="Password"
        />
        <password-input
          name="passwordConfirmation"
          v-model="payload.password_confirmation"
          label="Password Confirmation"
        />

        <!-- submit buttons -->
        <b-button
          type="submit"
          variant="primary"
          block
          @click="onSubmit"
          :disabled="loading"
        >
          Reset Password
        </b-button>
      </b-form>
    </validation-observer>
    <b-card-text class="text-center mt-2">
      <span>New on our platform? </span>
      <b-link to="/registration">
        <span>&nbsp;Create an account</span>
      </b-link>
    </b-card-text>
  </auth-master-layout>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from "vee-validate";
import { BButton, BCardText, BForm, BLink } from "bootstrap-vue";
import { required } from "@validations";
import store from "@/store";
import PasswordInput from "@/layouts/components/form/PasswordInput";
import AuthMasterLayout from "@/views/auth/AuthMasterLayout";

export default {
  components: {
    AuthMasterLayout,
    PasswordInput,
    BLink,
    BCardText,
    BForm,
    BButton,
    ValidationObserver,
  },
  data() {
    return {
      payload: {
        password: "",
        password_confirmation: "",
        token: this.$route.query.token,
        email: this.$route.query?.email || undefined,
      },
      // validation rulesimport store from '@/store/index'
      required,
    };
  },
  computed: {
    loading() {
      return store.getters["auth/isAuthLoading"];
    },
  },
  methods: {
    async onSubmit() {
      const success = await this.$refs.loginValidation.validate();
      if (success) {
        this.$store.dispatch("auth/resetPassword", this.payload);
      }
    },
  },
};
</script>

<style lang="scss"></style>
